<template>
    <NuxtLayout>
        <Page class="error">
            <div class="error-message">
                <h1>Oh dear! Something's not right!</h1>
                <h2>Try starting from <NuxtLink to="/">home</NuxtLink> again.</h2>
            </div>
        </Page>

    </NuxtLayout>
</template>

<script>
export default {
    layout: 'default'
}
</script>